// get all data in form and return object
function getFormData(form) {
  var elements = form.elements;
  var honeypot;

  var fields = Object.keys(elements).filter(function(k) {
    if (elements[k].name === "honeypot") {
      honeypot = elements[k].value;
      return false;
    }
    return true;
  }).map(function(k) {
    if(elements[k].name !== undefined) {
      return elements[k].name;
    // special case for Edge's html collection
    }else if(elements[k].length > 0){
      return elements[k].item(0).name;
    }
  }).filter(function(item, pos, self) {
    return self.indexOf(item) == pos && item;
  });

  var formData = {};
  fields.forEach(function(name){
    var element = elements[name];

    // singular form elements just have one value
    formData[name] = element.value;

    // when our element has multiple items, get their values
    if (element.length) {
      var data = [];
      for (var i = 0; i < element.length; i++) {
        var item = element.item(i);
        if (item.checked || item.selected) {
          data.push(item.value);
        }
      }
      formData[name] = data.join(', ');
    }
  });

  // add form-specific values into the data
  formData.formDataNameOrder = JSON.stringify(fields);
  formData.formGoogleSheetName = form.dataset.sheet || "responses"; // default sheet name
  formData.formGoogleSendEmail
    = form.dataset.email || ""; // no email by default

  return {data: formData, honeypot: honeypot};
}

function handleFormError(form) {
  form.reset();
  var errorMessage = form.querySelector(".error_message");
  if (errorMessage) {
    errorMessage.style.display = "block";
  }
  enableAllButtons(form);
}

function handleFormSubmit(event) {  // handles form submit without any jquery
  event.preventDefault();           // we are submitting via xhr below
  var form = event.target;
  var formData = getFormData(form);
  var data = formData.data;

  // If a honeypot field is filled, assume it was done so by a spam bot.
  if (formData.honeypot) {
    return false;
  }

  disableAllButtons(form);
  
  var jsonObj = {"sheet_id": "watchpager", data: { 'name': data.name, 'email': data.email, 'message': data.message}};
  console.log(JSON.stringify(jsonObj));
  fetch("https://gsheet.sauray.net/submit", {
      method: "POST",
      body: JSON.stringify(jsonObj)
  }).then(resp => {
    if (resp.ok) {
      form.reset();
      var thankYouMessage = form.querySelector(".thankyou_message");
      if (thankYouMessage) {
        thankYouMessage.style.display = "block";
      }
    } else {
      handleFormError(form);    
    }
  }).catch(function(error) {
    handleFormError(form);    
  });
}

function loaded() {
  // bind to the submit event of our form
  var forms = document.querySelectorAll("form.support-form");
  for (var i = 0; i < forms.length; i++) {
    forms[i].addEventListener("submit", handleFormSubmit, false);
  }
};

function disableAllButtons(form) {
  var buttons = form.querySelectorAll("button");
  for (var i = 0; i < buttons.length; i++) {
    buttons[i].disabled = true;
  }
}

function enableAllButtons(form) {
  var buttons = form.querySelectorAll("button");
  for (var i = 0; i < buttons.length; i++) {
    buttons[i].disabled = false;
  }
}

document.addEventListener("DOMContentLoaded", loaded, false);
